import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 自定义引入样式
import '@/css/base.css';

//引入ElementUI组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 挂载ElementUI
Vue.use(ElementUI);
// 引入axios请求
import axios from '@/utils/request';
// 挂载axios
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
