import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 100000,
});

const fileFilterName = [
  'image/png',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const urls = [process.env.VUE_APP_BASE_URL, process.env.VUE_APP_BASE_NEWS_URL];

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    var token = sessionStorage.getItem('token');
    if (token) {
      config.headers.token = token;
    }
    if (config.urlIndex) {
      config.baseURL = urls[config.urlIndex];
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data.code === 0) {
      return response.data;
    }

    Message.error(response.data.message); // 提示错误信息

    if (response.data.code === 510) {
      router.push('/login');
    }
    // 对响应数据做点什么
    return false;
  },
  (error) => {
    // 对响应错误做点什么
    // 请求错误时做些事
    let status = '';
    if (error.request) {
      status = error.request;
    } else if (error.response) {
      status = error.response;
    }
    if (status) {
      switch (status.status) {
        case 400:
          error.message = '请求错误(400)';
          break;
        case 401:
          error.message = '未授权，请重新登录(401)';
          router.push('/401');
          break;
        case 403:
          error.message = '拒绝访问(403)';
          break;
        case 404:
          error.message = '请求出错(404)';
          router.push('/404');
          break;
        case 408:
          error.message = '请求超时(408)';
          break;
        case 500:
          error.message = '服务器错误(500)';
          router.push('/500');
          break;
        case 501:
          error.message = '服务未实现(501)';
          break;
        case 502:
          error.message = '网络错误(502)';
          break;
        case 503:
          error.message = '服务不可用(503)';
          break;
        case 504:
          error.message = '网络超时(504)';
          break;
        case 505:
          error.message = 'HTTP版本不受支持(505)';
          break;
        default:
          error.message = `连接出错!`;
      }
    } else {
      error.message = '连接服务器失败!';
    }
    Message.error(error.message); // 提示错误信息
    return Promise.reject(error);
  }
);

export default instance;
