<template>
  <el-container>
    <el-header>
      <titleBar></titleBar>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
</template>
<script>
import titleBar from '../components/title-bar.vue';
export default {
  components: { titleBar },
  data() {
    return {};
  },
  mounted() {
    this.$router.push({ path: '/about' });
  },
  methods: {},
};
</script>
<style lang="scss">
.el-container {
  height: 100vh;
}
.el-header {
  height: 88px !important;
}
::-webkit-scrollbar {
  display: none;
}
</style>
